import {
  // StyleParamType,
  createStylesParams,
} from '@wix/tpa-settings';
// import {HeadingTags} from "@wix/wixstores-client-core/dist/es/src/types/heading-tags";

type IStylesParams = {
  // gallery_productNameHtmlTag: StyleParamType.Font;
};

export default createStylesParams<IStylesParams>({
  // gallery_productNameHtmlTag: {
  //   type: StyleParamType.Font,
  //   getDefaultValue: (t) => HeadingTags.H3,
  // }
});
