/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {Filters} from '../Filters/Filters';
import {IGallerySantaProps, IPropsInjectedByViewerScript} from '../../../types/galleryTypes';
import {Sort} from '../Sort/Sort';
import s from './GalleryApp.scss';
import classNames from 'classnames';

import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'auto-bind-es5';
import _ from 'lodash';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {MobileSort} from '../MobileSort/MobileSort';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {MobileFilters} from '../MobileFilters/MobileFilters';
import {inlineStyleFix} from '../../../styles/inlineStyle';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class GalleryAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    this.announceIfFilterResultChanged(prevProps);
  }

  private announceIfFilterResultChanged(prevProps) {
    const haveFiltersChanged = !_.isEqual(this.props.globals.filterModels, prevProps.globals.filterModels);

    if (haveFiltersChanged && this.props.globals.products) {
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      );
    }
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private shouldShowGalleryApp() {
    return this.props.isLoaded && !this.props.globals.showShowLightEmptyState;
  }

  private shouldShowFilters() {
    return this.props.globals.filterModels.length > 0;
  }

  private shouldShowSort() {
    return this.props.globals.shouldShowSort;
  }

  private renderFilters() {
    const {clearFilters, shouldShowClearFilters} = this.props.globals;
    return <Filters shouldShowClearFiltersButton={shouldShowClearFilters} clearFilters={clearFilters} />;
  }

  private renderMobile() {
    return (
      <div className={s.mobileFiltersAndSort} data-hook={DataHook.MobileContainer}>
        {this.shouldShowFilters() && <MobileFilters />}
        {this.shouldShowSort() && <MobileSort areFiltersEnabled={this.shouldShowFilters()} />}
      </div>
    );
  }

  private renderSideFilters(classnames) {
    return (
      <aside className={classnames} data-hook={DataHook.SideFilters}>
        {this.renderFilters()}
      </aside>
    );
  }

  private renderDesktopSort() {
    return (
      <div className={classNames(s.sortRow)}>
        <Sort />
      </div>
    );
  }

  private renderProducts() {
    const {hasSelectedFilters} = this.props.globals;

    return <ProductList hasFilters={hasSelectedFilters} />;
  }

  public render() {
    const {full_width: isFullWidth, responsive} = this.props.globals.styleParams.booleans;
    const {shouldShowMobile} = this.props.globals;
    const isDesktop = !shouldShowMobile;

    if (!this.shouldShowGalleryApp()) {
      return null;
    }

    const shouldRenderSideFilters = isDesktop && this.shouldShowFilters();
    const shouldRenderDesktopSort = isDesktop && this.shouldShowSort();

    const classnames = {
      app: classNames({
        [s.galleryApp]: true,
        deviceMobile: shouldShowMobile,
      }),
      content: classNames({
        [s.content]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
      container: classNames({
        [s.container]: true,
        [s.filtersAndProductList]: isDesktop && this.shouldShowFilters(),
      }),
      filters: classNames({
        [s.filters]: true,
        [s.withSort]: shouldRenderDesktopSort,
      }),
    };

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
          <div data-hook={DataHook.Content} className={classnames.content}>
            {shouldRenderSideFilters && this.renderSideFilters(classnames.filters)}
            <div data-hook={DataHook.Container} className={classnames.container}>
              {shouldRenderDesktopSort && this.renderDesktopSort()}
              {shouldShowMobile && this.renderMobile()}
              {this.renderProducts()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const GalleryApp = withGlobals(withTranslations()(GalleryAppComp));
